<template>
  <div class="write">
    <div class="title">
      <span>视频</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="thead">
        <span class="flex1">序号</span>
        <span>开始时间</span>
        <span>结束时间</span>
        <span>时长</span>
        <span>大小</span>
        <span class="flex">查看</span>
      </div>
      <div class="scroll">
        <div class="tr">
          <span class="span span1">1</span>
          <span class="span">2020-03-24 12:30:20</span>
          <span class="span">2020-03-24 12:30:20</span>
          <span class="span">25分40秒</span>
          <span class="span">300M</span>
          <div class="flex">
            <span>查看</span>
            <span>下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 .5rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 6.825rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .475rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .thead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: .5rem;
      background-color: #02457B;
      color: #fff;
      font-size: .225rem;

      span {
        flex: 2;
        text-align: center;
      }

      .flex {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .flex1 {
        flex: 1;
      }
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - .5rem);

      &::-webkit-scrollbar {
        width: 0;
      }

      .tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: .375rem;

        .span {
          flex: 2;
          text-align: center;
          color: #01EEFD;
          font-size: .2rem;
        }

        .span1 {
          flex: 1;
        }

        .flex {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #01EEFD;
          font-size: .2rem;
        }
      }
    }
  }
}
</style>

<template>
  <div class="take">
    <div class="take-box">
      <div class="take-content">
        <div class="take-close" @click="close">
          <span class="el-icon-close"></span>
        </div>
        <div class="take-title">
          <div class="take-item" @click="take">
            <span class="el-icon-camera-solid camera"></span>
            <span>拍照</span>
          </div>
        </div>
        <div class="take-body">
          <div class="take-body-left" id="my_camera" ref="camera"></div>
          <div class="take-body-right">
            <div class="left">
              <div class="item">
                <span class="el-icon-refresh-right"></span>
              </div>
              <div class="item">
                <span class="el-icon-refresh-left"></span>
              </div>
              <div class="item">
                <span class="el-icon-zoom-in"></span>
              </div>
              <div class="item">
                <span class="el-icon-zoom-out"></span>
              </div>
            </div>
            <div class="right">
              <div class="list">
                <img v-if="url" :src="url" class="img" />
                <div v-else class="img"></div>
                <span>2017-08-035645jpg</span>
              </div>
            </div>
          </div>
        </div>
        <div class="take-footer">
          <el-button @click="submit">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Webcam from '../../../assets/js/webcam.min.js'
export default {
  data () {
    return {
      url: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      try {
        Webcam.set({
          width: this.$refs.camera.offsetWidth,
          height: this.$refs.camera.offsetHeight,
          dest_width: this.$refs.camera.offsetWidth,
          dest_height: this.$refs.camera.offsetHeight,
          image_format: 'jpeg',
          jpeg_quality: 90,
          constraints: {
            width: { exact: 2592 },
            height: { exact: 1944 }
          }
        })
        Webcam.attach('#my_camera')
      } catch (error) {
        console.log(error)
      }
    },
    take () {
      // eslint-disable-next-line camelcase
      Webcam.snap((data_uri) => {
        // console.log(data_uri)
        // eslint-disable-next-line camelcase
        this.url = data_uri
      })
    },
    submit () {
      this.$emit('close', this.url)
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.take {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .take-box {
    width: 90%;
    height: 90%;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    box-shadow: 0px 3px 6px 1px rgba(255, 24, 24, 0.1608);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .take-content {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      border: 4px solid rgba(1, 124, 219, .27);
      border-radius: 12px;
      box-sizing: border-box;

      .take-close {
        position: absolute;
        top: 1%;
        right: 1%;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
      }

      .take-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100px;
        padding: 10px 0;
        padding-left: 13px;
        border-bottom: 5px solid #1C52A3;
        box-sizing: border-box;

        .take-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          height: 100%;
          color: #fff;
          font-size: 20px;
          cursor: pointer;

          .camera {
            font-size: 25px;
          }
        }
      }

      .take-body {
        display: flex;
        width: 100%;
        height: calc(100% - 210px);
        margin-top: 10px;

        .take-body-left {
          width: calc(100% - 425px);
          height: 100%;
          background-color: #000000;
          border-radius: 6px;
        }

        .take-body-right {
          display: flex;
          width: 400px;
          height: 100%;
          padding: 20px 0;
          margin-left: 25px;
          background-color: rgba(28, 82, 163, .42);
          border-radius: 3px;
          border: 5px solid rgba(28, 82, 163, .42);
          box-sizing: border-box;

          .left {
            overflow-y: scroll;
            width: 76px;
            height: 100%;
            padding: 0 8px;
            box-sizing: border-box;

            &::-webkit-scrollbar {
              width: 0;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 64px;
              border-bottom: 1px solid #999999;
              box-sizing: border-box;
              color: #fff;
              font-size: 25px;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(100% - 76px);
            height: 100%;
            border-left: 1px solid #999999;
            box-sizing: border-box;
            color: #fff;
            font-size: 20px;

            .list {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 252px;
            }

            .img {
              width: 252px;
              height: 115px;
              margin-bottom: 10px;
              background-color: #fff;
              border-radius: 4px;
            }
          }
        }
      }

      .take-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        .el-button {
          border: none;
          border-radius: 0;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          color: #fff;
        }
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;

    .item {
      width: 40%;
      height: 100%;
    }

    .text {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 16px;
    }

    .look,
    .img {
      width: 400px;
      height: 300px;
      border: 1px solid black;
    }
  }

  .button {
    display: flex;
    justify-content: center;

    .el-button {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>

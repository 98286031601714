<template>
  <div class="write">
    <div class="title">
      <span>选择专家</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="left">
          <div class="select">
            <span>北京朝阳医院</span>
            <span class="el-icon-caret-bottom"></span>
          </div>
          <div class="scroll">
            <div v-for="(item, index) in list" :key="index" class="cell" :class="{ cell1: tabIndex === index }"
              @click="tabClick(index)">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search"></el-input>
          <el-table height="305" max-height="200" :show-header="false" :data="tableData" tooltip-effect="dark" style="width: 100%">
            <el-table-column>
              <!-- slot-scope="scope" -->
              <template>
                <div class="item">
                  <div class="img"></div>
                  <div class="info">
                    <div class="top">
                      <span>张三</span>
                      <span> 副主任医师</span>
                    </div>
                    <div class="bottom">
                      <span>北京朝阳医院</span>
                      <span>内科</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="55">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="btns">
        <el-button class="button">确定</el-button>
        <el-button>取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: ['内科', '内分泌科', '妇产科', '呼吸科', '麻醉科', '营养科'],
      tabIndex: 0,
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 546px;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      width: 100%;
      height: calc(100% - 66px);

      .left {
        width: 2.525rem;
        height: 100%;

        .select {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: .5rem;
          background-color: #024276;
          color: #fff;
          font-size: .225rem;
        }

        .scroll {
          width: 100%;
          height: calc(100% - .5rem);
          border: .05rem solid #043762;
          box-sizing: border-box;

          .cell {
            width: 100%;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            color: #fff;
            font-size: .225rem;

            span {
              cursor: pointer;
            }
          }

          .cell1 {
            color: #01EEFD;
          }
        }
      }

      .right {
        width: calc(100% - 2.525rem);
        height: 100%;
        padding: 0 .375rem;
        border: .05rem solid #043762;
        box-sizing: border-box;

        .el-input {
          width: 3.075rem;
          height: .475rem;
          margin-top: .125rem;
          border-radius: .075rem;
          font-size: .225rem;
          color: #fff;
        }

        /deep/.el-input__inner {
          background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
          border: 0;
        }

        /deep/.el-input__suffix {
          color: #fff;
          font-size: .225rem;
          right: .125rem;
        }

        .el-table {
          margin-top: .125rem;

          .item {
            display: flex;
            align-items: center;

            .img {
              width: .75rem;
              height: .75rem;
              background-color: #fff;
              border-radius: 50%;
            }

            .info {
              margin-left: .125rem;
              color: #fff;

              .top, .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 1.775rem;
                font-size: .225rem;
              }

              .bottom {
                font-size: .175rem;
              }
            }
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .325rem;
      margin: .25rem auto 0;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>

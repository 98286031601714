<template>
  <div class="popup">
    <div class="head">
      <span>发起会诊</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="scroll">
      <el-form>
        <div class="title">
          <span>会诊信息</span>
        </div>
        <div class="item">
          <span class="key">会诊类型</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">普通会诊</el-radio>
            <el-radio label="2">急会诊</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="key">会诊方式</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">视频会诊</el-radio>
            <el-radio label="2">图文会诊</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="key">会诊录制</span>
          <el-radio-group v-model="radio">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="item">
          <span class="key">发起人</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <span class="key">会诊说明</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <span class="key">科室</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="title">
          <span>会诊专家</span>
        </div>
        <div class="table">
          <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">医院名称</span>
            <span class="td">科室名称</span>
            <span class="td">医生姓名</span>
            <span class="td">特长</span>
            <span class="td">职称</span>
            <span class="td1">操作</span>
          </div>
          <div class="tr">
            <span class="td">1</span>
            <span class="td">古城营镇卫生院</span>
            <span class="td">骨科</span>
            <span class="td">李明明</span>
            <span class="td">游泳</span>
            <span class="td">主任医师</span>
            <span class="td1">
              <span>编辑</span>
              <span>删除</span>
            </span>
          </div>
        </div>
        <div class="title margin border">
          <span>患者信息</span>
        </div>
        <div class="title1 margin">
          <span>基本信息</span>
        </div>
        <div class="info">
          <div class="span">
            <span>姓名：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>性别：</span>
            <el-select class="info-input" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="span">
            <span>年龄：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>身份证：</span>
            <el-input class="info-input1" placeholder="请输入"></el-input>
            <img src="../../../assets/img/scan.png" class="scan" @click="take" />
          </div>
          <div class="span">
            <span>出生日期：</span>
            <el-date-picker
              type="date"
              class="info-input2"
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="span">
            <span>手机号：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>职业：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>现住址：</span>
            <el-input class="info-input1" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>户籍地址：</span>
            <el-input class="info-input1" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>出生日期：</span>
            <el-date-picker
              type="date"
              class="info-input2"
              placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="title1 margin">
          <span>一般检查</span>
        </div>
        <div class="info">
          <div class="span">
            <span>身高：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>体重：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>血糖测量次数：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>绑定血糖仪：否</span>
          </div>
          <div class="span">
            <span>绑定血压计：是</span>
          </div>
          <div class="span">
            <span>最近测量血糖:</span>
          </div>
          <div class="span">
            <span>最近测量血压：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
          <div class="span">
            <span>血压测量次数：</span>
            <el-input class="info-input" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="info">
          <div class="info-span">
            <span class="info-text">病情描述</span>
            <el-input resize="none" type="textarea">
            </el-input>
          </div>
          <div class="info-span">
            <span class="info-text">既往史</span>
            <el-input resize="none" type="textarea">
            </el-input>
          </div>
          <div class="info-span">
            <span class="info-text">诊断描述</span>
            <div class="info-box">
              <el-input class="info-input3" placeholder="请输入"></el-input>
              <el-input resize="none" type="textarea" class="textarea">
            </el-input>
            </div>
          </div>
        </div>
        <div class="title1 margin">
          <span>处方</span>
        </div>
        <div class="tab">
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 0 }" @click="tabClick(0)">西药处方</span>
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 1 }" @click="tabClick(1)">中药处方</span>
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 2 }" @click="tabClick(2)">治疗处方</span>
        </div>
        <div class="add">
          <span>开发机构：机构名称</span>
          <el-button @click="openAddPopup">新增</el-button>
        </div>
        <div class="table" v-if="tabIndex === 0">
          <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">药物名称</span>
            <span class="td">用法</span>
            <span class="td">用量</span>
            <span class="td">单位</span>
            <span class="td">用药开始日期</span>
            <span class="td">用药停止日期</span>
            <span class="td">机构</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">拜新同</span>
            <span class="td">每日一次</span>
            <span class="td">一次30mg</span>
            <span class="td">mg</span>
            <span class="td">2020-02-02</span>
            <span class="td">2020-02-02</span>
            <span class="td">古城营镇卫生院</span>
            <span class="td">
              <span>删除</span>
            </span>
          </div>
        </div>
        <div class="table" v-else-if="tabIndex === 2">
          <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">处方内容</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">建议注意休息，避免进食辛辣刺激食物，多食富含维生素的蔬菜水果促进恢复，多饮水</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div>
        </div>
        <div class="label-box">
          <span>辅助检查</span>
        </div>
        <div class="tabs">
          <div class="items" :class="{ active: index === tabsIndex }" v-for="(item, index) in tabs" :key="index" @click="tabsClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="tabs-box">
          <div class="tabs-scroll"></div>
          <div class="tabs-bottom">
            <div class="tabs-input">
              <span>检查机构：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input placeholder="请输入"></el-input>
              <el-button>上传</el-button>
              <el-button>扫描</el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button @click="close">清空</el-button>
      <el-button @click="close" class="color">提交</el-button>
    </div>
    <Take v-if="showTake" @close="clsoeTake"></Take>
    <AddPopup1 v-if="showAddPopup1" @close="closeAddPopup1"></AddPopup1>
    <AddPopup3 v-if="showAddPopup3" @close="closeAddPopup3"></AddPopup3>
  </div>
</template>

<script>
import Take from './take.vue'
import AddPopup1 from './addPopup1.vue'
import AddPopup3 from './addPopup3.vue'
export default {
  components: {
    AddPopup1,
    AddPopup3,
    Take
  },
  data () {
    return {
      radio: '1',
      date: '',
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      url: '',
      tabsIndex: 0,
      tabs: Object.freeze(['生化', '血常规', '尿常规', '心电图', 'B超', 'DR', 'CT', '其他']),
      showTake: false,
      showAddPopup1: false,
      showAddPopup3: false
    }
  },
  mounted () {},
  methods: {
    take () {
      this.showTake = true
    },
    clsoeTake (e) {
      console.log(e)
      if (e) {
        this.url = e
      }
      this.showTake = false
    },
    tabClick (index) {
      this.tabIndex = index
    },
    tabsClick (index) {
      this.tabsIndex = index
    },
    openAddPopup () {
      if (this.tabIndex === 0) {
        this.showAddPopup1 = true
      } else if (this.tabIndex === 2) {
        this.showAddPopup3 = true
      }
    },
    closeAddPopup1 () {
      this.showAddPopup1 = false
    },
    closeAddPopup3 () {
      this.showAddPopup3 = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 0 37px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #062138;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 22px;

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;
    }

    /deep/.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
      color: #fff !important;
      font-size: 18px;
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 15px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      height: 40px;
      margin-top: 10px;
      color: #01EEFD;
      font-size: 20px;
    }

    .title1 {
      width: 100%;
      margin-bottom: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .margin {
      margin-top: 16px;
    }

    .border {
      height: 40px;
      margin-top: 30px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .el-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .item {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: 18px;

      .key {
        margin-right: 5px;
      }

      .el-input {
        width: 130px;
      }

      .el-radio-group {
        display: flex;
      }

      .el-radio {
        margin-right: 10px;
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-radio {
        display: flex;
        align-items: center;
      }

      .el-select {
        width: 120px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #fff;
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-color: #FFFFFF;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        width: 18px;
        height: 18px;
        border-color: #01EEFD;
        background-color: #01EEFD;
      }

      /deep/.el-radio__inner::after {
        content: '✓';
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 10px;
        background-color: transparent;
      }
    }

    .button {
      margin: 0 20px;
    }

    .img-box {
      width: 200px;
      height: 150px;
      margin-left: 20px;
      background-color: #333;
    }

    .img {
      width: 200px;
      height: 150px;
    }

    .item1 {
      width: 100%;
      display: flex;
      align-items: center;
    }

    // .el-button {
    //   width: 80px;
    //   height: 30px;
    //   line-height: 30px;
    //   padding: 0;
    //   text-align: center;
    //   color: #fff;
    //   font-size: 18px;
    //   background-color: #01EEFD;
    //   border: 0;
    //   border-radius: 0;
    // }

    .el-tag {
      margin-left: 20px;
      padding: 0 20px;
      background-color: rgba(219, 219, 219, .25);
      border-radius: 15px;
      color: #fff;
      border: none;
    }

    /deep/.el-tag .el-tag__close {
      color: #fff;
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;

      .span {
        display: flex;
        align-items: center;
        width: 20%;
        margin-bottom: 20px;

        .info-input {
          width: 120px;
        }

        .el-select {
          width: 120px;
        }

        .info-input1 {
          width: calc(100% - 140px);
        }

        .info-input2 {
          width: 150px;
        }

        .scan {
          width: 26px;
          height: 26px;
          margin-left: 4px;
          cursor: pointer;
        }
      }

      .info-span {
        display: flex;
        margin-right: 20px;

        .info-text {
          white-space: nowrap;
        }

        .info-box {
          display: flex;
          flex-direction: column;
          width: 600px;
          margin-left: 10px;

          .info-input3 {
            width: 600px;
          }

          .textarea {
            width: 600px;

            /deep/.el-textarea__inner {
              width: 600px;
              height: 72px;
              margin-top: 8px;
              margin-left: 0;
            }
          }
        }
      }

      /deep/.el-textarea__inner {
        width: 200px;
        height: 120px;
        margin-left: 10px;
      }
    }

    .input {
      width: 404px;
      height: 40px;
      line-height: 40px;
      margin-left: 10px;
      border: 2px solid #01EEFD;

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 18px;
        padding-left: 40px;
      }

      /deep/.el-input__icon,
      /deep/.el-icon-search {
        margin-right: 40px;
        font-size: 18px;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      padding-left: 8px;
      box-sizing: border-box;

      .tab-item {
        width: 105px;
        height: 30px;
        line-height: 30px;
        margin-right: 30px;
        text-align: center;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .tab-item1 {
        background-color: #024276;
      }
    }

    .add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 46px;
      color: #01EEFD;
      font-size: 18px;
      padding: 0 10px;
      box-sizing: border-box;

      .el-button {
        width: 70px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        padding: 0;
        color: #fff;
        font-size: 14px;
        border: none;
        border-radius: 0;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      width: calc(100% - 20px);
      margin-left: 10px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 14px;

        .td {
          flex: 1;
          text-align: center;
        }

        .td1 {
          display: flex;
          justify-content: space-evenly;
          width: 120px;
        }

        .blue {
          color: #01EEFD;
        }

        .red {
          margin-left: 30px;
          color: #FF0000;
        }
      }

      .tr1 {
        height: 40px;
        background-color: #024276;
      }
    }

    .bottom {
      display: flex;
      width: calc(100% - 10px);
      height: 100px;
      margin-left: 10px;
    }

    .label-box {
      margin-top: 20px;
      margin-bottom: 7px;
      padding: 5px;
      background-color: #024276;
      color: #fff;
      font-size: 22px;
    }

    .tabs {
      display: flex;
      width: 100%;
      height: 40px;
      background-color: #024276;
      border: 3px solid #073F73;
      box-sizing: border-box;

      .items {
        flex: 1;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .active {
        background-color: #21A9B1;
      }
    }

    .tabs-box {
      width: 100%;
      height: 540px;
      border: 3px solid #073F73;
      box-sizing: border-box;

      .tabs-scroll {
        width: 100%;
        height: calc(100% - 70px);
      }

      .tabs-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70px;

        .tabs-input {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 18px;

          .el-select {
            width: 150px;
          }

          .el-input {
            width: 150px;
            margin-left: 10px;
          }

          .el-button {
            margin-left: 20px;
            border: none;
            border-radius: 0;
            background-color: #0C8B93;
            color: #fff;
          }
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;

    .el-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      margin-right: 50px;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: 14px;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

<template>
  <div class="video">
    <div class="video-top">
      <div class="video-left">
        <div class="title">
          <div class="div1"></div>
          <div class="div2"></div>
          <el-select v-model="value" placeholder="请选择" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div class="screen">
            <span class="el-icon-full-screen"></span>
          </div>
          <div class="div2"></div>
          <div class="div1"></div>
          <div class="round">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
        </div>
        <div class="logo" @click="close">
          <span>logo</span>
        </div>
      </div>
      <div class="video-right">
        <div class="video-item">
          <img src="../../../assets/img/camera.png" class="camera" />
          <div class="info">
            <div class="info-left organ">
              <span class="el-icon-user-solid"></span>
            </div>
            <div class="info-right">
              <span>智慧正凯</span>
            </div>
          </div>
        </div>
        <div class="video-item">
          <!-- <img src="../../../assets/img/camera.png" class="camera" /> -->
          <div class="info">
            <div class="info-left">
              <span class="el-icon-microphone"></span>
            </div>
            <div class="info-right">
              <span>小程序用户</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video-bottom">
      <el-button>远程会诊</el-button>
      <el-button>邀请</el-button>
      <el-button>管理成员</el-button>
      <el-button>录制</el-button>
      <el-button>应用</el-button>
      <el-button>设置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '会诊视图'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding-right: 11px;
  background-color: #071224;
  box-sizing: border-box;

  .video-top {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    background-color: red;

    .video-left {
      position: relative;
      width: calc(100% - 280px);
      height: 100%;
      background-color: #2D3033;

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 70px;
        padding-right: 20px;
        box-sizing: border-box;

        .div1 {
          width: 3px;
          height: 30px;
          background-color: #fff;
          border-radius: 1px;
        }

        .div2 {
          width: 23px;
          height: 30px;
          margin-left: 1px;
          margin-right: 1px;
          border: 2px solid #fff;
          border-radius: 2px;
          box-sizing: border-box;
        }

        .el-select {
          width: 116px;
          margin-left: 7px;
          margin-right: 28px;
        }

        .screen {
          margin-right: 12px;
          color: #fff;
          font-size: 34px;
        }

        .round {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 8px;
          height: 36px;
          margin-left: 22px;

          .item {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }

      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 156px;
        height: 156px;
        line-height: 156px;
        text-align: center;
        border-radius: 50%;
        background-color: #3E6DBA;
        color: #fff;
        font-size: 44px;
      }
    }

    .video-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 280px;
      height: 100%;
      background: linear-gradient(272deg, #2D3033 0%, #727476 100%);

      .video-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 260px;
        height: 160px;
        margin: 10px 0;
        background-color: #8A8A8C;

        .camera {
          width: 35px;
          height: 30px;
        }

        .info {
          display: flex;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 120px;
          height: 30px;
          background-color: #2A2A2A;

          .info-left {
            width: 30px;
            height: 100%;
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size: 20px;
          }

          .organ {
            background-color: #FF801A;
          }

          .info-right {
            width: calc(100% - 30px);
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }

  .video-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    .el-button {
      margin: 0 10px;
      border: none;
      background-color: #0176CC;
      color: #fff;
    }
  }
}
</style>

<template>
  <div class="popup">
    <div class="scroll">
      <el-form>
        <div class="head">
          <span>会诊详情</span>
        </div>
        <div class="title1 margin border">
          <span>申请信息</span>
          <span class="red">{{ title }}</span>
        </div>
        <div class="box">
          <div class="item">
            <span class="key">会诊类型：普通会诊</span>
          </div>
          <div class="item">
            <span class="key">会诊方式：视频会诊</span>
          </div>
          <div class="item">
            <span class="key">会诊视频：否</span>
          </div>
          <div class="item">
            <span class="key">会诊时间：2020-01-02 13：00：00</span>
          </div>
          <div class="item">
            <span class="key">允许调整会诊时间：是</span>
          </div>
          <div class="item item1">
            <span class="key">会诊专家</span>
            <el-button @click="openSelectExperts" class="color">选择</el-button>
            <el-tag size="small" closable>医院名称 - 科室名称 - 姓名</el-tag>
            <el-tag size="small" closable>医院名称 - 科室名称 - 姓名</el-tag>
            <el-tag size="small" closable>医院名称 - 科室名称 - 姓名</el-tag>
            <el-tag size="small" closable>医院名称 - 科室名称 - 姓名</el-tag>
            <span class="key key1">会诊费用：125元</span>
          </div>
        </div>
        <div class="head">
          <span>患者信息</span>
        </div>
        <div class="title1 margin border">
          <span>基本信息</span>
        </div>
        <div class="info">
          <span class="span">姓名：陈菊英</span>
          <span class="span">性别：女</span>
          <span class="span">年龄：43岁</span>
          <span class="span">身份证：422121195011092466</span>
          <span class="span">出生日期：1978-09-09</span>
          <span class="span">手机号：19996131427</span>
          <span class="span">职业：自由职业</span>
          <span class="span">现住址：北京市朝阳区</span>
          <span class="span">户籍地址：北京市朝阳区</span>
          <span class="span">创建时间：2021-06-23 19:38</span>
          <span class="span">家族史：父亲 高血压</span>
          <span class="span span2">家庭住址:河北省石家庄市XX镇</span>
        </div>
        <div class="title1 margin border">
          <span>一般检查</span>
        </div>
        <div class="info">
          <span class="span">身高：163cm</span>
          <span class="span">体重：53kg</span>
          <span class="span">血糖测量次数 ：0次</span>
          <span class="span">绑定血糖仪：否</span>
          <span class="span">最近测量血糖：</span>
          <span class="span">血压测量次数：2次</span>
          <span class="span">绑定血压计：是</span>
          <span class="span span1">检查时间：2020-10-04 11：0019：09</span>
        </div>
        <div class="title1 margin title2">
          <span class="width">病情描述：</span>
          <el-input resize="none" type="textarea" :rows="3">
          </el-input>
        </div>
        <div class="title1 margin title2">
          <span class="width">诊断结论：</span>
          <el-input resize="none" type="textarea" :rows="3">
          </el-input>
        </div>

        <!-- <div class="title1 margin">
          <span>诊断描述</span>
        </div>
        <el-input class="input" placeholder="请输入诊断疾病编码/拼音码" prefix-icon="el-icon-search">
        </el-input> -->
        <div class="title1 margin">
          <span>处方</span>
        </div>
        <div class="tab">
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 0 }" @click="tabClick(0)">西药处方</span>
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 1 }" @click="tabClick(1)">中药处方</span>
          <span class="tab-item" :class="{ 'tab-item1': tabIndex === 2 }" @click="tabClick(2)">治疗处方</span>
        </div>
        <div class="add">
          <span>开发机构：机构名称</span>
        </div>
        <div class="table" v-if="tabIndex === 0">
          <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">药物名称</span>
            <span class="td">用法</span>
            <span class="td">用量</span>
            <span class="td">单位</span>
            <span class="td">用药开始日期</span>
            <span class="td">用药停止日期</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">拜新同</span>
            <span class="td">每日一次</span>
            <span class="td">一次30mg</span>
            <span class="td">mg</span>
            <span class="td">2020-02-02</span>
            <span class="td">2020-02-02</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div>
        </div>
        <div class="table" v-else-if="tabIndex === 2">
          <div class="tr tr1">
            <span class="td">序号</span>
            <span class="td">处方内容</span>
            <span class="td">操作</span>
          </div>
          <div class="tr">
            <span class="td">00001</span>
            <span class="td">建议注意休息，避免进食辛辣刺激食物，多食富含维生素的蔬菜水果促进恢复，多饮水</span>
            <span class="td">
              <span class="blue">编辑</span>
              <span class="red">删除</span>
            </span>
          </div>
        </div>
        <div class="add">
          <span>邀请方信息</span>
        </div>
        <div class="bottom">
          <div class="item">
            <span class="key">发起人</span>
            <el-input size="small" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <span class="key">联系方式</span>
            <el-input size="small" placeholder="请输入"></el-input>
          </div>
          <div class="item">
            <span class="key">科室</span>
            <el-select size="small" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <template v-if="status === '2'">
          <div class="title1 margin border">
            <span>会诊信息</span>
          </div>
          <div class="add add1">
            <span>实际开始时间：2020-03-15 16:00</span>
            <span>实际结束时间：2020-03-15 17:00</span>
            <div class="video">
              <span>会诊视频</span>
              <el-button @click="openVideo">查看</el-button>
            </div>
            <div class="video">
              <span>共享文件</span>
              <el-button @click="openShareFiles">查看</el-button>
            </div>
          </div>
          <div class="add">
            <span>会诊意见</span>
          </div>
          <div class="table">
            <div class="tr tr1">
              <span class="td">序号</span>
              <span class="td">会诊机构</span>
              <span class="td">会诊专家</span>
              <span class="td">会诊意见</span>
              <span class="td">附件</span>
              <span class="td">状态</span>
              <span class="td">出具时间</span>
              <span class="td">操作</span>
            </div>
            <div class="tr">
              <span class="td">00001</span>
              <span class="td"></span>
              <span class="td">张三</span>
              <span class="td">会诊意见会诊意见</span>
              <span class="td">2020-02-28 17:00</span>
              <span class="td">已出具</span>
              <span class="td"></span>
              <span class="td">查看详情</span>
            </div>
          </div>
          <div class="title1 margin title2">
            <span class="width">会诊总结：</span>
            <el-input resize="none" type="textarea" :rows="3">
            </el-input>
          </div>
          <div class="add add1 add2">
            <span>总结医生：主持医生姓名</span>
            <span>报告提交时间：2020-03-27 16:00</span>
          </div>
          <div class="add">
            <span>会诊记录</span>
            <el-button>新增记录</el-button>
          </div>
          <div class="table">
            <div class="tr tr1">
              <span class="td">序号</span>
              <span class="td">记录内容</span>
              <span class="td">记录人员</span>
              <span class="td">记录时间</span>
              <span class="td">最后编辑时间</span>
              <span class="td">操作</span>
            </div>
            <div class="tr">
              <span class="td">00001</span>
              <span class="td">记录内容详情</span>
              <span class="td">张三</span>
              <span class="td">2020-02-28 17:00</span>
              <span class="td">2020-02-28 17:00</span>
              <span class="td">
                <span>查看详情</span>
                <span class="left">编辑</span>
                <span class="left">操作</span>
              </span>
            </div>
          </div>
        </template>
      </el-form>
    </div>
    <div class="btns">
      <el-button @click="close">返回</el-button>
      <el-button class="color">开始会诊</el-button>
    </div>
    <!-- 共享文件 -->
    <ShareFiles v-if="showShareFiles" @close="closeShareFiles"></ShareFiles>
    <!-- 视频 -->
    <Video v-if="showVideo" @close="closeVideo"></Video>
    <!-- 选择专家 -->
    <SelectExperts v-if="showSelectExperts" @close="closeSelectExperts"></SelectExperts>
  </div>
</template>

<script>
import ShareFiles from './shareFiles.vue'
import Video from './video.vue'
import SelectExperts from './selectExperts.vue'
export default {
  components: {
    ShareFiles,
    Video,
    SelectExperts
  },
  props: {
    status: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      radio: '1',
      date: '',
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      statusList: ['待开始', '进行中', '已结束', '被驳回', '待审核'],
      showShareFiles: false,
      showVideo: false,
      showSelectExperts: false
    }
  },
  computed: {
    title () {
      return this.statusList[this.status]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openShareFiles () {
      this.showShareFiles = true
    },
    closeShareFiles () {
      this.showShareFiles = false
    },
    openVideo () {
      this.showVideo = true
    },
    closeVideo () {
      this.showVideo = false
    },
    openSelectExperts () {
      this.showSelectExperts = true
    },
    closeSelectExperts () {
      this.showSelectExperts = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  padding: 10px 30px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #062138;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    color: #01EEFD;
    font-size: 22px;

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;
    }

    /deep/.el-input__inner {
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
      color: #fff !important;
      font-size: 18px;
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    min-width: 16.25rem;
    height: calc(100% - 48px);
    padding: 0 5px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      width: 100%;
      color: #01EEFD;
      font-size: 20px;
      font-weight: bold;
    }

    .title1 {
      width: calc(100% - 20px);
      margin-left: 20px;
      margin-bottom: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;

      .red {
        margin-left: 40px;
        color: #FF0000;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .title2 {
      display: flex;

      .width {
        width: 120px;
      }
    }

    .margin {
      margin-top: 16px;
    }

    .border {
      height: 40px;
      margin-top: 20px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .el-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 30px;
      box-sizing: border-box;
    }

    .item {
      width: 20%;
      margin-top: 18px;
      color: #01EEFD;
      font-size: 18px;

      .key {
        margin-right: 5px;
      }

      .key1 {
        margin-left: 19px;
      }

      .color {
        margin-left: 20px;
        background-color: #01EEFD !important;
      }

      .el-input {
        width: 130px;
      }

      .el-radio {
        margin-right: 10px;
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #fff;
      }

      /deep/.el-radio__inner {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-color: #FFFFFF;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        width: 18px;
        height: 18px;
        border-color: #01EEFD;
        background-color: #01EEFD;
      }

      /deep/.el-radio__inner::after {
        content: '✓';
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        font-size: 10px;
        background-color: transparent;
      }
    }

    .item1 {
      width: 100%;
      display: flex;
      align-items: center;
    }

    /deep/.el-textarea__inner {
      width: 80%;
      height: 80px;
      margin-left: 10px;
      color: #fff;
      background-color: transparent;
      border: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .el-button {
      width: 80px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      text-align: center;
      color: #fff;
      font-size: 18px;
      background-color: #01EEFD;
      border: 0;
      border-radius: 0;
    }

    .el-tag {
      margin-left: 20px;
      padding: 0 20px;
      background-color: rgba(219, 219, 219, .25);
      border-radius: 15px;
      color: #fff;
      border: none;
    }

    /deep/.el-tag .el-tag__close {
      color: #fff;
    }

    .info {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;

      .span {
        width: 20%;
        margin-bottom: 10px;
        // width: 212px;
      }

      .span1 {
        width: 60%;
      }

      .span2 {
        width: 80%;
      }
    }

    .input {
      width: 404px;
      height: 40px;
      line-height: 40px;
      margin-left: 30px;
      border: 2px solid #01EEFD;

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 18px;
        padding-left: 40px;
      }

      /deep/.el-input__icon,
      /deep/.el-icon-search {
        margin-right: 40px;
        font-size: 18px;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      padding-left: 30px;
      box-sizing: border-box;

      .tab-item {
        width: 105px;
        height: 30px;
        line-height: 30px;
        margin-right: 30px;
        text-align: center;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .tab-item1 {
        background-color: #01EEFD;
      }
    }

    .add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 46px;
      color: #01EEFD;
      font-size: 18px;
      padding: 0 30px;
      box-sizing: border-box;

      .video {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 156px;
      }
    }

    .add1 {
      width: 80%;
    }

    .add2 {
      width: 40%;
    }

    .el-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      padding: 0;
      color: #fff;
      font-size: 14px;
      border: none;
      border-radius: 0;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: calc(100% - 50px);
      margin-left: 30px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 14px;

        .td {
          flex: 1;
          text-align: center;
        }

        .td1 {
          display: flex;
          padding: 0 20px;
          box-sizing: border-box;
        }

        .td2 {
          align-items: center;
        }

        .blue {
          color: #01EEFD;
        }

        .red {
          margin-left: 30px;
          color: #FF0000;
        }

        .left {
          margin-left: 26px;
        }
      }

      .tr1 {
        height: 40px;
        background-color: #024276;
      }
    }

    .bottom {
      display: flex;
      width: calc(100% - 30px);
      // height: 100px;
      margin-left: 30px;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-top: 10px;

    .el-button {
      height: 26px;
      margin-right: 50px;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: 14px;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>

<template>
  <div class="referral">
    <div class="head">
      <el-button @click="openAddConsultation">发起新的会诊</el-button>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="table">
      <el-table :data="tableData" border height="100%">
        <!-- <el-table-column type="selection" width="50">
      </el-table-column> -->
        <el-table-column prop="name" label="会诊单号">
        </el-table-column>
        <el-table-column prop="date" label="邀请时间">
        </el-table-column>
        <el-table-column prop="date" label="会诊时间">
        </el-table-column>
        <el-table-column prop="type" label="会诊类型">
        </el-table-column>
        <el-table-column prop="type1" label="会诊方式">
        </el-table-column>
        <el-table-column prop="type2" label="受邀医院">
        </el-table-column>
        <el-table-column prop="type" label="受邀科室">
        </el-table-column>
        <el-table-column prop="type" label="受邀医生">
        </el-table-column>
        <el-table-column prop="type" label="会诊费用">
        </el-table-column>
        <el-table-column prop="type" label="会诊意见">
        </el-table-column>
        <el-table-column prop="type" label="会诊总结">
        </el-table-column>
        <el-table-column prop="type" label="诊后记录">
        </el-table-column>
        <el-table-column prop="type" label="会诊地点">
        </el-table-column>
        <el-table-column prop="status" label="状态">
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <div class="flex">
              <span @click="openEnter">进入</span>
              <span @click="openSeeConsultation(scope)">查看</span>
              <span>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <SeeConsultation v-if="showSeeConsultation" :status='status' @close="closeSeeConsultation"></SeeConsultation>
    <AddConsultation v-if="showAddConsultation" @close="closeAddConsultation"></AddConsultation>
    <!-- 进入 -->
    <Enter v-if="showEnter" @close="closeEnter"></Enter>
  </div>
</template>

<script>
import SeeConsultation from './seeConsultation.vue'
import AddConsultation from './addConsultation.vue'
import Enter from './enter.vue'
export default {
  components: {
    SeeConsultation,
    AddConsultation,
    Enter
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待开始'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '进行中'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      showSeeConsultation: false,
      showAddConsultation: false,
      showEnter: false,
      status: '0'
    }
  },
  methods: {
    openSeeConsultation (item) {
      const list = ['待开始', '进行中', '已结束', '被驳回', '待审核']
      this.status = list.indexOf(item.row.status) + ''
      this.showSeeConsultation = true
    },
    openEnter () {
      this.showEnter = true
    },
    closeEnter () {
      this.showEnter = false
    },
    closeSeeConsultation () {
      this.showSeeConsultation = false
    },
    openAddConsultation () {
      this.showAddConsultation = true
    },
    closeAddConsultation () {
      this.showAddConsultation = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #fff;
  box-sizing: border-box;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      background-color: #01EEFD;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;
    }

    // /deep/.el-input__inner {
    //   background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    //   border: 1px solid #1F72D3;
    //   color: #fff !important;
    //   font-size: 18px;
    // }

    // /deep/ .el-input__suffix {
    //   color: #fff;
    //   font-size: 18px;
    // }
  }

  .table {
    width: 100%;
    height: calc(100% - 200px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      color: #0e75d4;
      font-size: 13px;

      span {
        cursor: pointer;
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
}
</style>
